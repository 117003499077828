import React from "react";
import "./header.styles.scss";
import headerImage from "../../images/header.jpg";
import { SocialIcon } from "react-social-icons";

export default function Header() {
  return (
    <header>
      <img className="header_image" src={headerImage} />
      <div className="text_box">
        <h1 className="header_text">Deividas Kozlovas</h1>
        <p className="header_subtext">Full stack web developer</p>
      </div>
      <div className="social_icons">
        <SocialIcon
          url="https://lt.linkedin.com/in/deividas-kozlovas-a86606183"
          // style={{ height: 40, width: 40 }}
          target="_blank"
        />
        <SocialIcon
          url="mailto:kozlovas.deividas@gmail.com"
          // style={{ height: 40, width: 40 }}
          target="_blank"
        />
      </div>
    </header>
  );
}
