import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import contactsImage from "../../images/contacts.jpg";
import "./contacts.styles.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Contacts() {
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const recaptchaRef = useRef();
  const form = useRef();

  const emailSend = () =>
    toast.success("Email send", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const fillCaptcha = () =>
    toast.error("Please complete the reCAPTCHA", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const sendEmail = (e) => {
    e.preventDefault();

    if (!recaptchaResponse) {
      fillCaptcha();
      return;
    }

    emailjs
      .sendForm(
        "service_fj2zb3d",
        "template_vau1a8a",
        form.current,
        "s7nTGbAeIBGWvioBx"
      )
      .then(
        (result) => {
          emailSend();
          recaptchaRef.current.reset();
          e.target.reset();
        },
        (error) => {
          console.log("Error sending email");
        }
      );
  };

  const handleRecaptchaChange = (response) => {
    setRecaptchaResponse(response);
  };

  return (
    <>
      <ToastContainer />
      <div className="contacts_container">
        <img className="contacts_image" src={contactsImage} />
        <div className="contacts_box">
          <h5>Say hello</h5>
          <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input
              className="contacts_input"
              type="text"
              name="user_name"
              required
              minLength={5}
            />
            <label>Email</label>
            <input
              className="contacts_input"
              type="email"
              name="user_email"
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
            <label>Message</label>
            <textarea
              className="contacts_input"
              name="message"
              required
              minLength={15}
            />
            <input className="button" type="submit" value="Send" />
            <div className="captcha">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_CAPTCHA}
                onChange={handleRecaptchaChange}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
