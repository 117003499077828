import React from "react";
import Header from "../components/header/Header.components";
import About from "../components/about/About.components";
import Skills from "../components/skills/Skills.components";
import Work from "../components/work/Work.components";
import Contacts from "../components/contacts/Contacts.components";
import Footer from "../components/footer/Footer.components";
import "./homepage.scss";

export default function Main() {
  return (
    <>
      <Header />
      <About />
      <Work />
      <Contacts />
      <Footer />
    </>
  );
}
