import React from "react";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import about from "../../images/skullturo.png";
import weather from "../../images/weather.png";
import mociutesukis from "../../images/mociutesukis.png";

import { ExternalLink } from "react-external-link";
import "./work.styles.scss";

export default function Work() {
  return (
    <div className="box">
      <h4>Here are some past projects that I have worked on</h4>
      <CardGroup>
        <Card>
          <ExternalLink href="https://skullturo.com">
            <Card.Img variant="top" src={about} />
            <Card.Body>
              <Card.Text>
                Personal project of online shop specialing in selling 3D printed
                models and is built with React and Laravel. Customers can
                conveniently make payments using PayPal
              </Card.Text>
            </Card.Body>
          </ExternalLink>
        </Card>
        <Card>
          <ExternalLink href="https://weather.deivdev.com/">
            <Card.Img variant="top" src={weather} />
            <Card.Body>
              <Card.Text>
                Clothing recommendations for the next three days will be
                provided based on the weather conditions in the city you enter
                as input. Web was built with React and Laravel.
              </Card.Text>
            </Card.Body>
          </ExternalLink>
        </Card>
        <Card>
          <ExternalLink href="https://mociutesukis.lt/">
            <Card.Img variant="top" src={mociutesukis} />
            <Card.Body>
              <Card.Text>
                Ecomers shop that sells locally grown vegetables and fruits. The
                Web is built using React and Laravel. Project currently still in
                production.
              </Card.Text>
            </Card.Body>
          </ExternalLink>
        </Card>
      </CardGroup>
    </div>
  );
}
