import React from "react";
import "./footer.styles.scss";
import { SocialIcon } from "react-social-icons";

export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <footer>
      <div className="footer_container">
        <p>All materials © Deividas Kozlovas {year}</p>
        <div className="footer_box">
          <SocialIcon
            url="https://lt.linkedin.com/in/deividas-kozlovas-a86606183"
            style={{ height: 35, width: 35 }}
            target="_blank"
          />
          <SocialIcon
            url="mailto:kozlovas.deividas@gmail.com"
            style={{ height: 35, width: 35 }}
            target="_blank"
          />
        </div>
      </div>
    </footer>
  );
}
