import React from "react";
import "./about.styles.scss";
import aboutImage from "../../images/about.jpg";

export default function About() {
  return (
    <>
      <div className="about_container">
        <img className="about_image" src={aboutImage} />
        <div className="about_box">
          <h2>ABOUT ME</h2>
          <p className="about_text">
            Hi, I'm a junior full-stack web developer who's just starting out on
            my programming journey. I'm passionate about creating dynamic and
            user-friendly websites, and I'm committed to continuously learning
            and improving my skills. As a new programmer, I bring a fresh
            perspective and enthusiasm to every project I work on, and I'm
            excited to see where my journey in programming takes me.
          </p>
        </div>
      </div>
    </>
  );
}
